<template>
  <div
    v-show="ListTopInfoState.show"
    class="product-list-v2__topinfo"
    :class="{'product-list-v2__topinfo-new': searchSuggestNew, 'product-list-v2_topinfo-new-warp' : showSuggestCorrection}"
  >
    <section v-if="ListTopInfoState.showCoupon">
      <s-alert
        type="info"
        show-icon
        closable
        @close="closeCoupon"
      >
        {{ couponTip }}
      </s-alert>
    </section>

    <section 
      v-if="!searchSuggestNew"
      class="product-list-v2__topinfo-title"
      tabindex="0"
      :aria-label="`${title} ${sum} ${language.SHEIN_KEY_PWA_15336}`"
      role="heading"
    >
      <h1
        v-show="!ListTopInfoState.showSilLabel && !ListTopInfoState.showPostCorrection && title"
        class="product-list-v2__topinfo-title-name"
        v-text="title"
      ></h1>
      <span
        v-if="!hideGoodsNum"
        class="product-list-v2__topinfo-title-sum"
        v-text="`${sum} ${language.SHEIN_KEY_PWA_15336}`"
      ></span>
    </section>

    <section
      v-if="ListTopInfoState.showSilLabel"
      class="product-list-v2__topinfo-sil"
    >
      {{ silLabelData[0] }}
      <span
        class="product-list-v2__topinfo-sil-name"
        v-text="title"
      ></span>
      {{ silLabelData[1] }}
    </section>

    <section
      v-if="hasCorrectionWord"
      class="product-list-v2__topinfo-post-correction"
    >
      {{ correctionData[0] }}
      <span
        class="product-list-v2__topinfo-post-correction_old"
        v-text="correctionData[1]"
      ></span>
      {{ correctionData[2] }}
      <span
        class="product-list-v2__topinfo-post-correction_new"
        @click="doSearch(correctionData[3])"
        v-text="correctionData[3]"
      ></span>
      {{ correctionData[4] }}
    </section>
    <section
      v-if="ListTopInfoState.showPostCorrection && searchSuggestNew && !isSuggestCorrection"
      class="product-list-v2__topinfo-correction-new"
    >
      <p class="product-list-v2__topinfo-new-result">
        {{ language.SHEIN_KEY_PWA_24650 && language.SHEIN_KEY_PWA_24650.replace('{0}',`${searchKeywords.keywords}`) }}
      </p>
      <p class="product-list-v2__topinfo-new-suggest">
        {{ language.SHEIN_KEY_PWA_22366 && language.SHEIN_KEY_PWA_22366.replace('{0}',`${searchKeywords.suggest_words}`) }}
      </p>
      <div class="product-list-v2__topinfo-new-bottom"></div>
    </section>
    <section
      v-if="showSuggestCorrection"
      class="product-list-v2__origin-word"
    >
      <p class="product-list-v2__origin-word-main">
        {{ language.SHEIN_KEY_PWA_22366 && language.SHEIN_KEY_PWA_22366.replace('{0}',`${searchKeywords.suggest_words}`) }}
      </p>
      <p 
        class="product-list-v2__origin-word-hint"
        @click=" (event) => clickOriginWords(event)"
        v-html="originSearchWordUrl"
      ></p>
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { Alert } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import { stringifyQueryString } from '@shein/common-function'
import { spaJump } from 'public/src/pages/common/utils/index.js'
import { SEARCH_TYPE_ENUM, getSearchSourceBySearchType } from 'public/src/pages/common/search_words/searchConst'
Vue.use(Alert)

export default {
  metaInfo() {
    return {
      script: [
        {
          innerHTML: JSON.stringify(this.metaData),
          type: 'application/ld+json',
        },
      ],
    }
  },
  props: {
    interception: Boolean,
    searchSuggestNew: {
      type: Boolean,
      default: false
    },
    isSuggestCorrection: {
      type: Boolean,
      default: false
    },
    hasCorrectionWord: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      couponTip: '',
      title: '',
      silLabelData: null,
      correctionData: null,
      metaData: null,
      paramUseSuggestWord: true,
    }
  },
  computed: {
    ...mapState('productList_v2', ['ComponentState']),
    ...mapGetters('productList_v2', [
      'locals',
      'language',
      'sum',
      'catInfo',
      'currentCat',
      'parentCats',
      'searchKeywords',
    ]),
    ListTopInfoState() {
      return this.ComponentState.ListTopInfo
    },
    hideGoodsNum() {
      return this.ComponentState.ProductList.config.hideGoodsNum
    },
    showSuggestCorrection() {
      // 建议纠错
      return this.isSuggestCorrection && this.paramUseSuggestWord && this.ListTopInfoState.showPostCorrection
    },
    originSearchWordUrl () {
      const { SHEIN_KEY_PWA_28631: hint } = this.language
      const { keywords } = this.searchKeywords
      if (!hint) {
        return ''
      }
      return hint.replace('{0}', `<a href="javascript:;" 
      class="link" 
      da-event-click="1-5-1-38"
      data-src-identifier="st=19\`sc=${keywords}\`sr=0\`ps=0"
      >${keywords}</a>`)
      
    }
  },
  watch: {
    catInfo: {
      handler(val){
        if (this.interception || val.requestType !== 'firstload') return

        this.getCouponTip()
        this.getTitle()
        this.getSilLabelData()
        this.getCorrectionData()
        this.getMetaData()
      },
      immediate: true
    },
    $route: {
      handler: function() {
        this.setParamUseSuggestWord()
      },
      immediate: true
    }
  },
  methods: {
    getCouponTip() {
      if (!this.ListTopInfoState.showCoupon) return this.couponTip = ''

      daEventCenter.triggerNotice({ daId: '1-5-1-16' })
      return this.couponTip = template(
        this.$route.query.CouponCode,
        this.language.SHEIN_KEY_PWA_17524
      )
    },
    getTitle() {
      switch (this.catInfo.type) {
        case 'entity':
          return this.title = this.currentCat.cat_name
        case 'selection':
          return this.title = this.currentCat.select_name_multi
        case 'daily_new':
          return this.title = this.language.SHEIN_KEY_PWA_15727
        case 'selling':
          return this.title = this.currentCat.valName
        case 'search':
          return this.title = this.searchKeywords.suggest_words || this.searchKeywords.origin_words
        default:
          return this.title = ''
      }
    },
    getSilLabelData() {
      if (!this.ListTopInfoState.showSilLabel) return this.silLabelData = null

      return this.silLabelData = this.language.SHEIN_KEY_PWA_18564
        .replace('{1}', this.catInfo.list_cat_name)
        .split('{0}')
    },
    getCorrectionData() {
      if (!this.ListTopInfoState.showPostCorrection) return this.correctionData = null

      const lang = this.language.SHEIN_KEY_PWA_16488.split(/{\d}/)
      return this.correctionData = [
        lang[0],
        this.searchKeywords.keywords,
        lang[1],
        this.searchKeywords.suggest_words,
        lang[2],
      ]
    },
    getMetaData() {
      let itemListElement
      switch (this.catInfo.type) {
        case 'entity':
          itemListElement = this.matchBreadcrumbs(this.parentCats).map(
            (_, i) => ({
              '@type': 'ListItem',
              position: i + 1,
              name: _.cat_url_name,
              item: `${this.locals.host}\/${_.cat_url_name}-c-${_.cat_id}.html`,
            })
          )
          break
        case 'selection':
          itemListElement = [
            {
              '@type': 'ListItem',
              position: 1,
              name: this.currentCat.select_name,
              item: this.locals.host + this.$route.path,
            },
          ]
          break
        case 'daily_new':
          itemListElement = [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Daily New',
              item: this.locals.host + this.$route.fullPath,
            },
          ]
          break
      }
      this.metaData = itemListElement
        ? {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement,
        }
        : null
    },
    matchBreadcrumbs(node) {
      const data = {
        cat_id: node.cat_id,
        cat_url_name: node.cat_url_name?.split(' ').join('-') || '',
      }
      return node.children?.length
        ? [data, ...this.matchBreadcrumbs(node.children[0])]
        : [data]
    },
    closeCoupon() {
      daEventCenter.triggerNotice({ daId: '1-5-1-17' })
      setTimeout(() => this.ListTopInfoState.showCoupon = false, 1000)
    },
    doSearch(newWord) {
      searchWordsGlobalAnalysis.set({
        result_type: 2,
        result_word: newWord,
      })
      const searchSource = getSearchSourceBySearchType(SEARCH_TYPE_ENUM.EDIT_SEARCH)
      this.$router.replace(`${this.locals.langPath}/pdsearch/${newWord}/?ici=${searchWordsGlobalAnalysis.getPageFrom(2)}&search_source=${searchSource}`)
    },
    clickOriginWords(event) {
      if (event?.target.classList.contains('link')) {
        searchWordsGlobalAnalysis.set({
          result_type: 19
        })
        // 跳转结果页
        const { keywords } = this.searchKeywords
        const oldParam = new URLSearchParams(location.search)
        let ici = oldParam.get('ici')
        const iciArr = ici.split('`')
        iciArr[1] = 'CorrectOriginalWord'
        iciArr[iciArr.length - 1] = 'PageSearch'
        ici = iciArr.join('`')
        const optionParams = {
          channel_id: oldParam.get('channel_id'),
          ici,
          search_source: getSearchSourceBySearchType(19),
          src_module: 'search',
          src_identifier: `st=19\`sc=${keywords}\`sr=0\`ps=0`,
          src_tab_page_id: window?.getSaPageInfo?.tab_page_id || '',
          dont_use_suggest_word: 1
        }
        let resultUrl = `${this.locals.langPath}/pdsearch/${encodeURIComponent(keywords)}/?${stringifyQueryString({
          queryObj: { ...optionParams },
        })}`
        spaJump({
          routerVm: this.$router,
          type: 'push',
          url: resultUrl,
        })
      }
    },
    setParamUseSuggestWord() {
      if (typeof window !== 'undefined') {
        const search = new URLSearchParams(window.location.search)
        this.paramUseSuggestWord = search.get('dont_use_suggest_word') !== '1'
      }
    }
  }
}
</script>

<style lang="less">
.product-list-v2 {
  &__topinfo {
    padding: 0.1067rem 0.32rem 0.32rem;
    line-height: 0.4rem;
    background: #fff;
    position: relative;
    z-index: 1;
  }
  &__topinfo-new {
    margin: 0;
  }
  &__topinfo-new-result {
    padding-top: 0.2667rem;
    color: @sui_color_gray_dark2;
    font-style: normal;
    font-weight: 400;
    font-size: 0.32rem;
    line-height: 0.3733rem;
  }
  &__topinfo-new-suggest { 
    font-style: normal;
    font-weight: 700;
    font-size: 0.3733rem;
    line-height: 0.4533rem;
    color: @sui_color_gray_dark1;
    padding-bottom: 0.2667rem;
    margin-top: 0.1067rem;
  }
  &__topinfo-new-bottom {
    height: 0.2667rem;
    width: 100%;
    background: #F6F6F6;
  }

  &__topinfo-title {
    font-size: 0.32rem;
    color: #999999;
  }

  &__topinfo-title-name {
    display: inline;
    font-size: 0.32rem;
    font-weight: normal;
    .margin-r(0.21rem);
  }

  &__topinfo-title-sum {
    display: inline-block;
  }

  &__topinfo-sil {
    font-size: 0.32rem;
    color: #999999;
    margin: 0.2133rem 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__topinfo-sil-name {
    font-weight: bold;
    color: #222222;
  }

  &__topinfo-post-correction {
    font-size: 0.32rem;
    color: @sui_color_gray_dark2;
    margin: 0.2133rem 0;

    &_old {
      color: #222222;
    }

    &_new {
      color: #222222;
      font-weight: bold;
    }
  }
}
.product-list-v2_topinfo-new-warp {
  padding: 0;
  border-bottom: 10px solid #F6F6F6;
    .product-list-v2__origin-word {
      padding: 12px 0 12px 12px;
      &-main {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: -0.33px;
        margin-bottom: 8px;
      }
      &-hint {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #666;
        .link {
          color: #2D68A8;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
}
</style>
