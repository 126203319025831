<template>
  <div
    class="tab-pic-nav-background"
    :style="{
      height: isTowRow ? '9.12rem' : '5.6rem'
    }"
  >
    <img
      src="//img.ltwebstatic.com/images3_ccc/2024/09/03/ae/1725345080c569df9fb23dbbab4b4c876e042f1890.png"
      alt="bg-img"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { debounce } from '@shein/common-function'
const isTowRow = ref(false)
let nav = null
let resizeObserver = null
// 判断是否是两行图文导航
const changeIsRow = debounce({
  func: () => {
    isTowRow.value = nav?.offsetHeight > 200
  },
  wait: 300
})
onMounted(() => {
  nextTick(() => {
    nav = document.querySelector('#scroll-top-pic-top-nav')
    resizeObserver = new ResizeObserver(() => changeIsRow())
    if (nav) {
      resizeObserver.observe(nav)
    }
  })
})
onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})
</script>

<style lang="less">
.tab-pic-nav-background {
  width: 100%;
  background-color: #edf4f6;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
