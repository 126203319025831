// 搜索结果页｜真实列表｜选品列表用的
import { getRecGoodsInfoBFF } from 'public/src/pages/rec_product_list/js/useRecommendApi.js'
import { getFeedbackRecInSession, getListPageInfo } from 'public/src/pages/product_list_v2/js/utils'

let recommenDrequestInstance = null
let isSearch = false, isSelectListPage = false, isEntityListPage = false, isPickInfoPage = false
export default {
  data() {
    return {
      useGoodIdList: [], // 已经点后推的商品Id
      localIndex: -1, // 当前点击商品的ID
      dynamicResultGood: [], // 点推商品
      // isShowRecommendComp: 0, // 是否有点后推功能, 顺带记录点推商品个数
      changeRecommendNum: 0, //经过商详过滤的后真实需要点推的数量
    }
  },
  activated() {
    // 因为共用container + keep-alive原因，所以路由更新也更新下页面类型
    let { query, meta } = this.$route
    // 不用catInfo的原因是： 从个人中心切到category 再进列表的时候catInfo居然无值，导致判断页面类型异常
    let pageInfos = getListPageInfo({ search_type: query?.search_type, type: meta?.type })
    isSearch = pageInfos?.isSearch
    isSelectListPage = pageInfos?.isSelectListPage
    isEntityListPage = pageInfos?.isEntityListPage
    isPickInfoPage = pageInfos?.isPickInfoPage
  },

  methods: {
    // 获取当前页面的点推商品个数
    getShowRecommendCompNum() {
      let { ListClickReco, SearchClickReco } = this.listAbtResult

      if(isSelectListPage || isEntityListPage) {
        return ListClickReco?.p?.ListClickRecoEntry
      }

      if(isSearch) {
        return SearchClickReco?.p?.SearchClickRecoEntry
      }

      return 0
    },
    // 请求点后推动态插坑 获取数据
    async insertRecommendGoodFun(goodsItemInfo, isPaid, otherPar = {}) {
      // abt 开关不打开就过滤
      let { ListClickReco, SearchClickReco } = this.listAbtResult
      // 针对真实和选品列表是否满足当前的入口控制
      let isEntryControl = (isSelectListPage || isEntityListPage) && (ListClickReco?.p?.ListClickRecoEntrance === '' || (ListClickReco?.p?.ListClickRecoEntrance === 'sbc' && this.catInfo?.entranceType === 'sbc') || (ListClickReco?.p?.ListClickRecoEntrance === 'category' && this.catInfo?.srctype === 'category'))

      // 当前的点推个数
      let isShowRecommendComp = ((isSelectListPage || isEntityListPage) && ListClickReco?.p?.ListClickRecoEntry) || (isSearch && SearchClickReco?.p?.SearchClickRecoEntry)

      // 当前页面应该对应的abt
      let nowPageRealTimeAbtVal = (isEntryControl && ListClickReco?.p?.ListClickRecoTime) || (isSearch && SearchClickReco?.p?.SearchClickRecoTime) || ''
      
      if(!isShowRecommendComp || !nowPageRealTimeAbtVal) {
        return
      }
      
      const goodsId = goodsItemInfo?.goods_id || ''
      const discountRate = goodsItemInfo?.pretreatInfo?.discountInfo?.originDiscountRate || 0
      const salePrice = goodsItemInfo?.salePrice?.usdAmount ?? ''
      const cateId = goodsItemInfo?.cat_id || ''
      const reportIndex = goodsItemInfo?.curClickIndex || 0 // 记录当前点击的主商品（埋点上报用）


      // 如果当前点击的商品已经点击过
      if(this.useGoodIdList.includes(goodsId) || this.useGoodIdList.includes(`${goodsId}`)) {
        return
      }

      recommenDrequestInstance && recommenDrequestInstance?.abort()
      recommenDrequestInstance = new SchttpAbortCon()
      
      let exposedGoodsId = [...new Set(this.goods?.slice(0, 240)?.map(item => item.goods_id)?.concat(this.useGoodIdList ?? []))]?.join(',') || '' // 过滤当前列表和已经点推出来的商品进行曝光
      let addCart = (nowPageRealTimeAbtVal === 'addBag' && 1) || (nowPageRealTimeAbtVal === 'noaddBag' && 0) || 0
      // 处理参数 && 请求接口
      let result = await getRecGoodsInfoBFF({
        goodsId,
        productLimit: +isShowRecommendComp,
        exposedGoodsId,
        discountRate,
        salePrice,
        isPaid,
        cateId,
        language: this.language || {},
        addCart,
        reportIndex,
        pageSelectId: this.catInfo?.select_id || '',
        pageCateId: this.catInfo?.cat_id || '',
        ...otherPar,
      }, recommenDrequestInstance)

      if(!result.length) {
        return 
      }
      // 存结果(触发时机不在)
      this.dynamicResultGood = result
    },

    // wiki: pageId=1470253023, 请注意和实时反馈的点后推逻辑完全不一样
    // 点后推 动态插入相关逻辑
    addRecommendProductHandle(isBackFromDetail, isCloseQuickPop, othObj = {}) {
      // abt 开关不打开就过滤
      let { flowInterestReco, ListClickReco, SearchClickReco } = this.listAbtResult
      // 针对真实和选品列表是否满足当前的入口控制
      let isEntryControl = (isSelectListPage || isEntityListPage) && (ListClickReco?.p?.ListClickRecoEntrance === '' || (ListClickReco?.p?.ListClickRecoEntrance === 'sbc' && this.catInfo?.entranceType === 'sbc') || (ListClickReco?.p?.ListClickRecoEntrance === 'category' && this.catInfo?.srctype === 'category'))
      // 当前的点推个数
      let isShowRecommendComp = ((isSelectListPage || isEntityListPage) && ListClickReco?.p?.ListClickRecoEntry) || (isSearch && SearchClickReco?.p?.SearchClickRecoEntry)
      // 当前页面应该对应的abt
      let nowPageRealTimeAbtVal = (isEntryControl && ListClickReco?.p?.ListClickRecoTime) || (isSearch && SearchClickReco?.p?.SearchClickRecoTime) || (isPickInfoPage && flowInterestReco?.p?.flowInterestRecoTime) || ''
      if(!isShowRecommendComp || !nowPageRealTimeAbtVal || !this.dynamicResultGood?.length) {
        return
      }

      const { ymal = '', similar = '' } = getFeedbackRecInSession() || {}
      // let recomArr = this.dynamicResultGood.map(item=> `${item.goods_id}`)
      // // 过滤商详推荐逻辑
      // if(recomArr.some(item=> ymal.split(',')?.includes(item) || similar.split(',')?.includes(item))) {
      //   return
      // }

      // 过滤商详推荐逻辑
      let recomArr = this.dynamicResultGood.filter(item=> !ymal.split(',')?.includes(`${item.goods_id}`) && !similar.split(',')?.includes(`${item.goods_id}`) )?.slice(0, isShowRecommendComp || 2)
      // 过滤完了
      if(!recomArr.length) {
        return
      }
      // 拿到过滤且剩余
      this.dynamicResultGood = recomArr
      this.changeRecommendNum = recomArr?.length

      // 判断abt条件与当前用户交互是否匹配
      const abtMatchInteractiveFun = async (goodsId, addBagStatus) => {
        // 如果当前点击的商品已经点推成功过
        if(this.useGoodIdList?.includes?.(goodsId)) {
          return
        }

        // 商品加车成功时，展示点后推
        if(nowPageRealTimeAbtVal === 'addBag' && addBagStatus == 1) {
          // 更改状态
          this.useGoodIdList.push(`${goodsId}`)
          // 找到当前点击的商品index(！！！！！！这里同步productList中给瀑布流组件的数组， 为了解决当前列表存在内容体插坑，位置不对问题)
          let localIndex = (this.$refs.ProductList?.waterItems ?? []).findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
          if(localIndex < 0) {
            return
          }

          // 记录当前点击
          this.localIndex = localIndex
          // 记录推荐商品（不需要点后推）
          this.useGoodIdList.push(...this.dynamicResultGood.map(item=>`${item.goods_id}`))
          // 插入数据,数据进栈尾
          this.goods?.push(...this.dynamicResultGood)
          return 
        }
        
        // 点击商品就推
        if(nowPageRealTimeAbtVal === 'click' && goodsId) {
          // 重复请求的原因 参见wiki:pageId=1470253023
          if(addBagStatus == 1 && this.curClickItem) {
            await this.insertRecommendGoodFun(this.curClickItem, this.Results?.sheinClubInfo?.isPaid, {
              addCart: 1
            })
          }
          
          // 更改状态
          this.useGoodIdList.push(`${goodsId}`)
          // 找到当前点击的商品index(！！！！！！这里同步productList中给瀑布流组件的数组， 为了解决当前列表存在内容体插坑，位置不对问题)
          let localIndex = (this.$refs.ProductList?.waterItems ?? []).findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))

          if(localIndex < 0) {
            return
          }

          // 记录当前点击
          this.localIndex = localIndex
          // 记录推荐商品（不需要点后推）
          this.useGoodIdList.push(...this.dynamicResultGood.map(item=>`${item.goods_id}`))
          // 插入数据,数据进栈尾
          this.goods?.push(...this.dynamicResultGood)
          return
        }

        // 商品没有加车成功时
        if(nowPageRealTimeAbtVal === 'noaddBag' && addBagStatus == 0) {
          // 更改状态
          this.useGoodIdList.push(`${goodsId}`)
          // 找到当前点击的商品index(！！！！！！这里同步productList中给瀑布流组件的数组， 为了解决当前列表存在内容体插坑，位置不对问题)
          let localIndex = (this.$refs.ProductList?.waterItems ?? []).findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
          if(localIndex < 0) {
            return
          }

          // 记录当前点击
          this.localIndex = localIndex
          // 记录推荐商品（不需要点后推）
          this.useGoodIdList.push(...this.dynamicResultGood.map(item=>`${item.goods_id}`))
          // 插入数据,数据进栈尾
          this.goods?.push(...this.dynamicResultGood)
          return
        }
      }

      // ！！之前分开写是因为首页信息流 点推入参的商品ID问题
      // 是否从商详返回
      if(isBackFromDetail) {
        // 获取当前的加车信息
        const { goodsId, addBagStatus } = getFeedbackRecInSession() || {}
        abtMatchInteractiveFun(goodsId, +addBagStatus)
        this.dynamicResultGood = [] // 清空之前的点推数据
        return 
      }

      // 快加车关闭
      if(isCloseQuickPop) {
        // 获取当前的加车信息
        const { goodsId, addBagStatus } = othObj || {}
        abtMatchInteractiveFun(`${goodsId}`, +addBagStatus)
        this.dynamicResultGood = [] // 清空之前的点推数据
        return 
      }
      
    }

  },
}


