<template>
  <section 
    v-expose="{
      id: '1-5-1-39',
      code: exposeCode,
      delayReady: 1500,
      data: {
        ...exposeGoodsInfo,
        trends_code: trendData.trendId
      }
    }"
    class="search-trend-card__container"
    @click="goTrendLanding(trendData.trendCardNewStyle ? null : trendData.product[0])"
  >
    <!-- <section 
      :style="{'visibility': titleStyle !== 0 ? 'visible': 'hidden'}"
      class="search-trend-card__wrapper"
    > -->
    <section 
      class="search-trend-card__wrapper"
    >
      <section class="search-trend-card__left">
        <div class="search-trend-card__left_logo">
          <img
            v-if="trendData.logoObj.trendsImg"
            :src="trendData.logoObj.trendsImg" 
            class="search-trend-card__left_logo_trend trend_img"
          />
          <i
            v-else
            class="search-trend-card__left_logo_trend trend_text"
          >{{ trendData.logoObj.trendsText }}</i>
          <i 
            v-if="trendData.tagObj.type === 'hot'"
            class="search-trend-card__left_logo_hot"
          >{{ trendData.tagObj.text }}</i>
          <i 
            v-if="trendData.tagObj.type === 'new'"
            class="search-trend-card__left_logo_new"
          >{{ trendData.tagObj.text }}</i>
        </div>
        <!-- 元素不展示，做样式计算 计算完后把他干掉，不然fixed/absolute 定位会影响搜索框吸顶 -->
        <div 
          v-if="titleStyle === 0"
          class="search-trend-card__left_title off-screen-rendering"
        >
          <b
            ref="trendTitleDom"
            class="search-trend-card__left_title_text"
          >
            #{{ trendData.trendTitle }}
            <Icon
              class="search-trend-card__left_title_icon"
              name="sui_icon_more_circle_right_16px_2"
              size="16px" 
            />
          </b>
        </div>
        <!-- 元素不展示，做样式计算 -->
        <!-- 一行或者两行，没有省略号 -->
        <div
          v-if="titleStyle === 1" 
          class="search-trend-card__left_title"
        >
          <b
            class="search-trend-card__left_title_text"
          >
            #{{ trendData.trendTitle }}
            <Icon
              class="search-trend-card__left_title_icon"
              name="sui_icon_more_circle_right_16px_2" 
              size="16px" 
            />
          </b>
        </div>
        <!-- 两行有省略号 -->
        <div 
          v-if="titleStyle === 2" 
          class="search-trend-card__left_title special_line"
        >
          <b class="search-trend-card__left_title_text">
            #{{ trendData.trendTitle }}
          </b>
        </div>
        <!-- 【新样式】单行隐藏 -->
        <p
          class="search-trend-card__left_description"
          :style="{
            'margin-top': titleStyle === 0 ? '0.906rem': '0.0533rem',
            'white-space': trendData.trendCardNewStyle ? 'nowrap' : 'normal',
            'display': trendData.trendCardNewStyle ? 'block' : null
          }"
        >
          {{ trendData.trendDesc }}
        </p>
        <!-- 【老样式】左边的折线图 👇-->
        <!-- 【新样式】隐藏该模块 -->
        <div
          v-if="!trendData.trendCardNewStyle"
          class="search-trend-card__left_line-chart"
        >
          <div class="line-chart_wrap">
            <p 
              class="search-trend-card__left_line-chart_hint"
            >
              <Icon
                class="search-trend-card__left_line-chart_hint_icon"
                name="sui_icon_search_trends_16px" 
                size="11px" 
              />
              <span 
                v-if="trendData.growthLabel.languageText.indexOf('{0}') === 0"
              >{{ growthRate }}</span>
              <b :class="trendData.growthLabel.growthType">{{ (trendData.growthLabel.languageText).replace('{0}', '') }}</b>
              <span 
                v-if="trendData.growthLabel.languageText.indexOf('{0}') > 0 "
              >{{ growthRate }}</span>
            </p>
            <img 
              class="search-trend-card__left_line-chart_png"
              :src="trendData.growthLabel.growthImage" 
            />
          </div>
        </div>
      </section>
      <!-- 【老样式】右边的大图 👇 -->
      <!--【新样式】增加一个 section 放右边的折线图，同时隐藏下面这块 -->
      <section
        v-if="trendData.trendCardNewStyle"
        class="search-trend-card__right_line-chart"
      >
        <div class="line-chart_wrap">
          <p 
            class="search-trend-card__right_line-chart_hint"
          >
            <Icon
              class="search-trend-card__right_line-chart_hint_icon"
              name="sui_icon_search_trends_16px" 
              size="11px" 
            />
            <span 
              v-if="trendData.growthLabel.languageText.indexOf('{0}') === 0"
            >{{ trendData.growthLabel.growthRate }}</span>
            <b :class="trendData.growthLabel.growthType">{{ (trendData.growthLabel.languageText).replace('{0}', '') }}</b>
            <span 
              v-if="trendData.growthLabel.languageText.indexOf('{0}') > 0 "
            >{{ trendData.growthLabel.growthRate }}</span>
          </p>
          <img 
            class="search-trend-card__right_line-chart_png"
            :src="trendData.growthLabel.growthImage" 
          />
        </div>
      </section>
      <section 
        v-else
        class="search-trend-card__right"
        @click.stop="goTrendLanding(trendData.product[0], true)"
      >
        <div 
          :class="['search-trend-card__right-img',{'trend-img': !trendData.product[0]?.goods_img}]"
          :style="'background-image: url('+ trendData.mianImg +')'"
        >
        </div>
        <div 
          v-if="trendData.product[0]?.goods_img"
          class="search-trend-card__right_price"
        >
          <ProductCardPriceSimple 
            :goods-info="trendData.product[0]"
            :language="language"
            :isPaid="!!sheinClubInfo?.isPaid" 
            :config="{
              hidePriceBottomLabel: true,
              priceColor: trendData.priceColor
            }"
            :camelPriceStyle="{
              before: 'font-size: 14px;',
              after: 'font-size: 10px;',
            }"
          />
        </div>
      </section>
      <!-- 【新样式】增加多商品板块 -->
      <section
        v-if="trendData.trendCardNewStyle"
        class="search-trend-card__bottom"
      >
        <div class="search-trend-card__bottom_goods">
          <div 
            v-for="(item, index) in trendData.product"
            :key="index"
            class="search-trend-card__bottom_goods-item"
            @click.stop="goTrendLanding(item, true)"
          >
            <img
              class="goods-list__img"
              :src="item.goods_img"
            />
            <div class="goods-list__price">
              <ProductCardPriceSimple 
                :goods-info="item"
                :language="language"
                :isPaid="!!sheinClubInfo?.isPaid" 
                :config="{
                  hidePriceBottomLabel: true,
                  priceColor: trendData.priceColor
                }"
                :camelPriceStyle="{
                  before: 'font-size: 11px;',
                  after: 'font-size: 11px;',
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

defineComponent({
  name: 'SearchTrendCard'
})
</script>

<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { parseQueryString, stringifyQueryString } from '@shein/common-function'
import { defineProps, ref, onMounted, getCurrentInstance, computed } from 'vue'
import ProductCardPriceSimple from 'public/src/pages/components/product/item_v3/components/ProductCardPrice/ProductCardPriceSimple.vue'

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}
const router = useRouter()
const props = defineProps({
  trendData: {
    type: Object,
    default: () => {}
  },
  sheinClubInfo: {
    type: Object,
    default() {
      return {}
    }
  },
  exposeCode: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  constantData: { type: Object, default: () => ({}) },
})

const exposeGoodsInfo = computed(() => {
  let goods_to_list = props.trendData.product?.length ? '' : '-'
  props.trendData.product.forEach(product => {
    const { goods_img, goods_id: goodsId, goods_sn: skuId, productRelationID: spuId, salePrice, retailPrice, mall_code } = product || {}
    const page = 1
    const index = 0
    const operation = 1
    const recMark = ''
    const price = salePrice?.usdAmount
    const originPrice = retailPrice?.usdAmount
    // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
    const goodsStr = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|estimated_pri_${originPrice}\`\`\`mall_${mall_code}`
    if (goods_img) {
      if (goods_to_list) {
        goods_to_list += `,${goodsStr}`
      } else {
        goods_to_list += goodsStr
      }
    }
  })
  
  return  { goods_to_list }
})

const trendTitleDom = ref(null)
const titleStyle = ref(0)

/**
 * Navigates to the trend landing page based on the provided goods data and click event.
 *
 * @param {Object} goods - 商品数据
 * @param {boolean} isClickGoods - 是否点击商品触发
 */
const goTrendLanding = (goods, isClickGoods) => {
  const { cat_id: catId = '' } = goods || {}
  const goodsId = goods?.goods_id || ''
  const { langPath = '' } = gbCommonInfo || {}
  const {  src_module = '', src_identifier, src_tab_page_id = '' } = parseQueryString(location.search)
  const srcData = {
    src_module,
    src_identifier,
    src_tab_page_id,
    page_from: 'PageSearchResult',
  }
  const { trendId, selectId, selectUrlId } =  props.trendData

  // ! 埋点 START
  const { goods_img, goods_sn: skuId, productRelationID: spuId, salePrice, retailPrice, mall_code } = goods || {}
  const page = 1
  const index = 0
  const operation = 1
  const recMark = ''
  const price = salePrice?.usdAmount
  const originPrice = retailPrice?.usdAmount
  // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
  const goods_to_list = `${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|estimated_pri_${originPrice}\`\`\`mall_${mall_code}`
  const extraData = {
    trends_code: trendId
  }
  if (goods && isClickGoods) {
    extraData.goods_to_list = goods_to_list
  }
  daEventCenter.triggerNotice({
    daId: '1-5-1-42',
    extraData,
  })
  // ! 埋点 END
  const url = `${langPath}/trend-landing?trendId=${trendId}&selectId=${selectId}&selectUrlId=${selectUrlId}&catId=${catId}${
    goodsId ? '&adp=' + goodsId : ''
  }&${stringifyQueryString({ queryObj: srcData })}`
  router.push(url)
}

const growthRate = computed(() => {
  const { trendData, constantData } = props

  const GB_cssRight = constantData.GB_cssRight
  const rate = trendData?.growthLabel?.growthRate || ''

  return GB_cssRight ? rate.replace(/(\d+(\.\d+)?)%/, '%$1') : rate
})



onMounted(() => {
  titleStyle.value = trendTitleDom.value.offsetHeight >= 42 ? 2 : 1
})
</script>

<style lang="less">
@unit: 375/100vw;
@trend-color: #9462FF;
html[mir='rtl'] .search-trend-card__left_line-chart_hint_icon,
html[mir='rtl'] .search-trend-card__left_title_icon{
  transform: translate3d(0, 0, 0) rotateY(180deg);
}
.trend-card_text-2-line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.trend-card_text-3-line {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.off-screen-rendering {
  position: fixed !important;
  right: -4000px;
  bottom: -4000px;
  width: 100%;
  &.search-trend-card__left_title {
    .search-trend-card__left_title_text{
      .trend-card_text-3-line;
    }
  }
}
.search-trend-card {
  &__container {
    padding: 88/ @unit 12 / @unit 0;
    margin-top: -82 / @unit;
  }
  &__wrapper {
    box-sizing: border-box;
    border-radius: 8 / @unit;
    border: 1px solid #EDE8F6;
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(3.5999999046325684px);
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto;
  }
  &__left {
    flex: 1;
    overflow: hidden;
    &_logo {
      position: absolute;
      left: -1px;
      top: -1px;
      display: flex;
      &_trend {
        border-radius: 8 / @unit 0 8 / @unit 0;
        padding: 4 / @unit 6 / @unit;
        margin-right: 6 / @unit;
        background: linear-gradient(90deg, rgba(148, 98, 255, 0.10) 0.29%, rgba(148, 98, 255, 0.15) 99.68%);
        &.trend_text {
          display: inline-block;
          color: @trend-color;
          font-family: "SF Pro Display";
          font-size: 12 / @unit;
          line-height: 12 / @unit;
          font-style: normal;
          font-weight: 700;
        }
        &.trend_img {
          box-sizing: content-box;
          border-radius: 8 / @unit 0 8 / @unit 0;
          height: 12 / @unit;
          width: auto;
        }
      }
      &_hot, &_new {
        font-family: "SF Pro";
        font-size: 9 / @unit;
        font-style: italic;
        font-weight: 858;
        line-height: 11 / @unit;
        text-transform: capitalize;
        word-spacing: 1 / @unit;
        padding: 5.5 / @unit 3.5 / @unit;
        display: inline-block;
        max-width: 105 / @unit;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &_hot {
        color: #FE7134;
        text-shadow: 0px 2.882px 21.619px rgba(0, 0, 0, 0.15);
      }
      &_new {
        color: #40C04A;
        text-shadow: 0px 2.909px 21.818px rgba(0, 0, 0, 0.15);
      }
    }
    &_title {
      display: inline-block;
      position: relative;
      margin-top: 24 / @unit;
      &_icon {
        transform: translateY(-1.5 / @unit);
      }
      &.special_line {
        max-width: 100%;
        .search-trend-card__left_title_text {
          text-overflow: ellipsis;
          overflow-x: hidden;
          width: 80%;
          word-break: break-all;
        }
       &::after {
          content: '';
          background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjguMDAwMDIiIGN5PSI4LjAwMDE2IiByPSI2LjgzMzMzIiB0cmFuc2Zvcm09InJvdGF0ZSgtOTAgOC4wMDAwMiA4LjAwMDE2KSIgc3Ryb2tlPSIjMjMxMDREIj4KPC9jaXJjbGU+CjxwYXRoIGQ9Ik02LjU5OTYxIDUuMTI0NEw5LjQyODA0IDcuOTUyODJMNi41OTk2MSAxMC43ODEyVjEwLjc4MTIiIHN0cm9rZT0iIzIzMTA0RCI+CjwvcGF0aD4KPC9zdmc+) no-repeat;
          width: 16 / @unit;
          height: 16 / @unit;
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(-12 / @unit , -6 / @unit);
        }
      }
    }
    &_title_text,
    &_description {
      margin-left: 8 / @unit;
      color: #23104D;
      font-family: "SF Pro";
      font-style: normal;
      text-transform: capitalize;
    }
    &_title_text {
      font-size: 18 / @unit;
      font-weight: 1000;
      height: 21;
      line-height: 21px;
      .trend-card_text-2-line;
    }
    &_description {
      font-size: 11 / @unit;
      font-weight: 400;
      line-height: 13 / @unit;
      opacity: 0.4;
      .trend-card_text-2-line;
    }
    &_line-chart {
      margin-left: 8 / @unit;
      padding: 12 / @unit 0;
      .line-chart_wrap {
        position: relative;
        margin: 0 auto;
        width: 149 / @unit;
        height: 53 / @unit;
      }
      &_hint {
        position: absolute;
        border-radius: 100 / @unit;
        background: @trend-color;
        padding: 1 / @unit 6 / @unit;
        color: #F4EFFF;
        font-family: "SF Pro";
        font-size: 10 / @unit;
        font-weight: 510;
        text-transform: capitalize;
        word-spacing: 3 / @unit;
        transform: translateY( 3 / @unit);
        display: flex;
        align-items: center;
        gap: 0 2 /@unit;
        > b {
          display: inline-block;
          max-width: 64 / @unit;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          &.small_trending {
            max-width: 39 / @unit;
          }
          &.breakout {
            max-width: 57 / @unit;
          }
        }
        &_icon {
          transform: translate( 1 / @unit, -1 / @unit);
        }
        &::after {
          content:'';
          position: absolute;
          border: 2 / @unit solid;
          border-color: @trend-color @trend-color transparent transparent ;
          bottom: 0;
          left: 50%;
          width: 4 / @unit;
          height: 4 / @unit;
          transform-origin: center center;
          transform: translate(-50%, 1.5 / @unit) rotate(135deg);
        }
      }
      &_png {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__right {
    padding: 8 / @unit;
    position: relative;
    box-sizing: border-box;
    &-img {
      height: 100%;
      width: 120 / @unit;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      border-radius: 4 / @unit;
      &.trend-img {
        width: 142 / @unit;;
      }
    }
    &_price {
      position: absolute;
      right: 14 / @unit;
      bottom: 14 / @unit;
      display: flex;
      padding: 2 / @unit 0.025rem 2 / @unit 4 / @unit;
      border-radius: 2 / @unit;
      background: rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(4.25 / @unit);
    }
    &_line-chart {
      margin: 0 8 / @unit;
      padding: 8 / @unit 0;
      .line-chart_wrap {
        position: relative;
        margin: 0 auto;
        width: 149 / @unit;
        height: 53 / @unit;
      }
      &_hint {
        position: absolute;
        border-radius: 100 / @unit;
        background: @trend-color;
        padding: 1 / @unit 6 / @unit;
        color: #F4EFFF;
        font-family: "SF Pro";
        font-size: 10 / @unit;
        font-weight: 510;
        text-transform: capitalize;
        word-spacing: 3 / @unit;
        transform: translateY( 3 / @unit);
        display: flex;
        align-items: center;
        gap: 0 2 /@unit;
        > b {
          display: inline-block;
          max-width: 64 / @unit;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          &.small_trending {
            max-width: 39 / @unit;
          }
          &.breakout {
            max-width: 57 / @unit;
          }
        }
        &_icon {
          transform: translate( 1 / @unit, -1 / @unit);
        }
        &::after {
          content:'';
          position: absolute;
          border: 2 / @unit solid;
          border-color: @trend-color @trend-color transparent transparent ;
          bottom: 0;
          left: 50%;
          width: 4 / @unit;
          height: 4 / @unit;
          transform-origin: center center;
          transform: translate(-50%, 1.5 / @unit) rotate(135deg);
        }
      }
      &_png {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__bottom {
    padding: 4 / @unit 8 / @unit 8 / @unit 8 / @unit;
    width: 100%;
    grid-column: 1 / span 2;
    border: 0px;
    &_goods {
      display: flex;
      justify-content: space-between;
      &-item {
        display: flex;
        flex-direction: column;
        position: relative;
       .goods-list__img {
          height: 78 / @unit;
          width: 78 / @unit;
          border-radius: 4 / @unit;
          border: 1px solid rgba(255, 255, 255, 0.70);
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
        }
        .goods-list__price {
          position: absolute;
          bottom: 4 / @unit;
          right: 4 / @unit;
          padding: 2px 3px;
          border-radius: 2px;
          background: rgba(255, 255, 255, 0.70);
          backdrop-filter: blur(4.25px);
          .product-card__camel-case-price {
            line-height: 1;
            height: 11px;
          }
          .product-card__price-simple .price-content {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
